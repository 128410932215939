@import '../resources/vars';
@import '../resources/mixins';

.header {
  background: var(--nav-bar-web-background);
  border-bottom: var(--nav-bar-web-border-bottom);
  padding-top: var(--nav-bar-web-padding-top);
  padding-bottom: var(--nav-bar-web-padding-bottom);
  --app-custom-grid-width: var(--nav-bar-web-grid-max-width);

  @include respond-to(sm, up) {
    background: var(--nav-bar-web-background-m, var(--nav-bar-web-background));
    padding-top: var(--nav-bar-web-padding-top-m, var(--nav-bar-web-padding-top));
    padding-bottom: var(--nav-bar-web-padding-bottom-m, var(--nav-bar-web-padding-bottom));
  }

  @include respond-to(lg, up) {
    background: var(--nav-bar-web-background-l, var(--nav-bar-web-background));
    padding-top: var(--nav-bar-web-padding-top-l, var(--nav-bar-web-padding-top));
    padding-bottom: var(--nav-bar-web-padding-bottom-l, var(--nav-bar-web-padding-bottom));
  }
}

.container {
  display: flex;
  justify-content: space-between;
  height: var(--app-custom-top-nav-height);
  align-items: center;
  gap: 16px;
}

.logo{
  width: auto;
  height: var(--nav-bar-web-logo-height);

  @include respond-to(sm, up) {
    height: var(--nav-bar-web-logo-height-m);
  }

  @include respond-to(lg, up) {
    height: var(--nav-bar-web-logo-height-l);
  }
}

.link {
  border-bottom: var(--nav-bar-web-link-border-bottom);
  border-top: var(--nav-bar-web-link-border-top);

  @include respond-to(sm, up) {
    border-bottom: var(--nav-bar-web-link-border-bottom-m);
    border-top: var(--nav-bar-web-link-border-top-m);
  }

  @include respond-to(lg, up) {
    border-bottom: var(--nav-bar-web-link-border-bottom-l);
    border-top: var(--nav-bar-web-link-border-top-l);
  }
  --cover-img-fluid-width: var(--nav-top-logo-width);
}

.navigation {
  display: none;
  gap: var(--nav-bar-web-links-gap);

  @include respond-to(sm, up) {
    gap: var(--nav-bar-web-links-gap-m);
    display: flex;
    height: 100%;
    align-items: center;
  }

  @include respond-to(lg, up) {
    gap: var(--nav-bar-web-links-gap-l);
  }

  .label {
    &:hover {
      background: var(--nav-bar-web-link-background-hover);
      text-decoration: var(--nav-bar-web-link-text-decoration-hover);
      color: var(--nav-bar-web-link-colour-hover);
    }
  }
}

.active {
  background: var(--nav-bar-web-link-current-background);
  border-radius: var(--nav-bar-web-link-current-border-radius);
  padding: var(--nav-bar-web-link-current-label-padding);
  width: fit-content;

  @include respond-to(sm, up) {
    padding: var(--nav-bar-web-link-current-label-padding-m, var(--nav-bar-web-link-current-label-padding));
  }

  @include respond-to(lg, up) {
    padding: var(--nav-bar-web-link-current-label-padding-l, var(--nav-bar-web-link-current-label-padding));
  }

  .label {
    color: var(--nav-bar-web-link-current-label-colour);

    &:hover {
      color: var(--nav-bar-web-link-current-label-colour-hover);
    }
  }
}

.label {
  @include with-styled-text(nav-bar-web-link);

  &:hover {
    color: var(--nav-bar-web-link-label-colour-hover);
  }
}

.icons {
  align-items: center;
  display: flex;
  height: 100%;

  @include respond-to(sm, up) {
    display: none;
  }
}

.collapsed-navigation {
  --app-custom-template-column-start: edge-start;
  --app-custom-template-column-end: edge-end;

  --app-custom-grid-width: var(--nav-bar-web-grid-max-width);
}

.collapsed-navigation {
  --app-custom-nav-bar-web-padding: calc(var(--nav-bar-web-padding-top) + var(--nav-bar-web-padding-bottom));

  position: absolute;
  top: calc(var(--app-custom-top-nav-height) + var(--app-custom-nav-bar-web-padding));
  width: 100%;
  background: var(--nav-bar-web-background); // new
  z-index: 1000;

  @include respond-to(sm, up) {
    display: none;
  }

  .collapsing-label {
    visibility: hidden;
    display: block;
    font-size: 0;
    line-height: 0;
  }

  .item {
    --app-custom-template-column-start: row-start;
    --app-custom-template-column-end: row-end;
  }

  .item {
    border-bottom: var(--nav-bar-web-link-border-bottom);
    border-top: var(--nav-bar-web-link-border-top);

    @include respond-to(sm, up) {
      border-bottom: var(--nav-bar-web-link-border-bottom-m);
      border-top: var(--nav-bar-web-link-border-top-m);
    }

    @include respond-to(lg, up) {
      border-bottom: var(--nav-bar-web-link-border-bottom-l);
      border-top: var(--nav-bar-web-link-border-top-l);
    }

    a, button {
      height: var(--nav-bar-web-link-height);
      display: flex;
      align-items: center;
    }
  }
}

.toggle {
  @include respond-to(xs, up) {
    display: none;
  }

  svg {
    width: var(--nav-bar-web-icon-size);
    height: var(--nav-bar-web-icon-size);
  }

  path {
    fill: var(--nav-bar-web-icon-menu-color);
  }

  &.expanded {
    path {
      fill: var(--nav-bar-web-icon-close-color);
    }
  }
}

.collapsed-items {
  display: flex;
  flex-direction: column;

  gap: var(--nav-bar-web-links-collapsed-gap);
  padding: var(--nav-bar-web-links-collapsed-padding);
}
