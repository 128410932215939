@import '../resources/vars';
@import '../resources/mixins';

.container {
  --app-input-container-direction: var(--input-radio-container-direction);
  --app-input-container-align-items: var(--input-radio-container-align-items);
  --app-input-container-justify-content: var(--input-radio-container-justify-content);
  --app-input-container-padding: var(--input-radio-container-padding);
  --app-input-container-border-width: var(--input-radio-container-border-width);
  --app-input-container-border-style: var(--input-radio-container-border-style);
  --app-input-container-border-color: var(--input-radio-container-border-color);

  --app-input-label-direction: var(--input-radio-label-direction);
  --app-input-label-justify-content: var(--input-radio-label-justify-content);
  --app-input-label-align-items: var(--input-radio-label-align-items);
  --app-input-label-margin: var(--input-radio-label-margin);

  --app-input-informer-margin: var(--input-radio-informer-margin);
}

.field {
  @at-root .input:checked + .icon {
    background-color: var(--input-radio-on-background-colour);
    border: var(--input-radio-on-border);
    border-radius: var(--input-radio-on-border-radius);
    box-shadow: var(--input-radio-on-box-shadow);
    height: var(--input-radio-on-background-size);
    width: var(--input-radio-on-background-size);

    svg {
      height: var(--input-radio-on-icon-size);
      width: var(--input-radio-on-icon-size);
    }

    path {
      fill: var(--input-radio-on-icon-colour);
    }

    circle {
      fill: var(--input-radio-on-icon-colour);
    }
  }

  @include outline-control('.input:focus + .icon');
}

.input {
  @extend %checkbox-reset;
}

.icon {
  background-color: var(--input-radio-off-background-colour);
  border: var(--input-radio-off-border);
  border-radius: var(--input-radio-off-border-radius);
  box-shadow: var(--input-radio-off-box-shadow);
  display: block;
  height: var(--input-radio-off-background-size);
  width: var(--input-radio-off-background-size);

  svg {
    height: var(--input-radio-off-icon-size);
    width: var(--input-radio-off-icon-size);
  }

  path {
    fill: var(--input-radio-off-icon-colour);
  }

  circle {
    fill: var(--input-radio-off-icon-colour);
  }

  &.pulse {
    animation: pulse 2s infinite;
  }
}

.label {
  flex: 1 1 100%;
  @include with-styled-text(input-radio-label);
  color: var(--app-custom-input-label-color);

  label {
    display: inline-block;
    width: 100%;
  }
}

.label-container {
  width: 100%;
  padding: var(--app-custom-input-controller-padding);
  background: var(--app-custom-input-controller-background);
  border: var(--app-custom-input-controller-border);
  border-radius: var(--app-custom-input-controller-border-radius);
}
