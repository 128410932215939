@import '../resources/vars';
@import '../resources/mixins';

.header {
  --app-custom-template-column-start: var(--app-template-question-header-column-start);
  --app-custom-template-column-end: var(--app-template-question-header-column-end);
}

.body {
  --app-custom-template-column-start: row-start;
  --app-custom-template-column-end: row-end;
  padding: var(--question-template-body-padding, 0 0 32px 0);

  @include respond-to(lg, up) {
    --app-custom-template-column-start: var(--app-template-question-body-column-start);
    --app-custom-template-column-end: var(--app-template-question-body-column-end);
  }
}

.image {
  padding: var(--question-section-image-padding, 0 0 16px 0);
}

.form-group {
  max-width: var(--input-group-max-width, 100%);
}
