@import '../resources/vars';
@import '../resources/mixins';

.container {
  background-color: var(--footer-bg-colour);
}

.homepage {
  background-image: var(--footer-bg-image-url);
  background-position: var(--footer-bg-image-position);
  background-repeat: var(--footer-bg-image-repeat);
  background-size: var(--footer-bg-image-size);
  padding: var(--footer-padding);
  margin: var(--footer-margin);

  @include respond-to(md, up) {
    margin: var(--footer-margin-m, var(--footer-margin));
    padding: var(--footer-padding-m, var(--footer-padding));
  }

  @include respond-to(lg, up) {
    margin: var(--footer-margin-l, var(--footer-margin));
    padding: var(--footer-padding-l, var(--footer-padding));
  }

  @at-root :global(.sidebar) & {
    border-right: var(--sidebar-page-footer-keyline);
  }
}

.body {
  margin: 0 auto;
  max-width: var(--footer-group-max-width);
  min-width: var(--footer-group-min-width);
  display: flex;
  flex-direction: column;
}

.icons {
  order: var(--footer-social-icons-flex-order);
  display: flex;
  flex-direction: var(--footer-social-group-direction);
  align-items: var(--footer-social-group-align-items);
  justify-content: var(--footer-social-group-justify-content);
  margin: var(--footer-social-group-margin);
  padding: var(--footer-social-group-padding);
}

.icon {
  filter: var(--footer-social-icons-colour);

  &:not(:last-child) {
    margin: var(--footer-social-icons-margin);
  }

  svg {
    height: var(--footer-social-icons-size);
    width: var(--footer-social-icons-size);

    path {
      fill: var(--footer-social-icons-color);
    }
  }
}

.buttons {
  order: var(--footer-buttons-flex-order);

  --app-custom-grid-cols: var(--footer-btn-group-columns, 2);
  --app-custom-grid-gap-row: var(--footer-btn-group-row-gutter);
  --app-custom-grid-gap-column: var(--footer-btn-group-column-gutter);

  margin: var(--footer-btn-group-margin);
  padding: var(--footer-btn-group-padding);

  @include respond-to(md, up) {
    --app-custom-grid-cols: var(--footer-btn-group-columns-m, 4);
  }
  @include respond-to(lg, up) {
    --app-custom-grid-cols: var(--footer-btn-group-columns-l, 4);
  }
}

.button-list-item {
  background-color: var(--footer-btn-bg-color);
  border: var(--footer-btn-border);
  border-radius: var(--footer-btn-border-radius);
  height: var(--footer-btn-height);
  line-height: var(--footer-btn-height);
  margin: var(--footer-btn-margin);
  padding: var(--footer-btn-padding);
  text-align: var(--footer-btn-align);
  display: flex;
}

.button, .button span {
  flex: 1;
  color: var(--footer-btn-label-color);
  font-family: var(--footer-btn-label-font-face);
  font-size: var(--footer-btn-label-font-size);
  font-weight: var(--footer-btn-label-font-weight);
  padding: var(--footer-btn-label-padding);
  text-decoration: var(--footer-btn-label-text-decoration);

  &:visited {
    color: var(--footer-btn-label-visited-color, var(--footer-btn-label-color));
  }
  &:active {
    color: var(--footer-btn-label-active-color, var(--footer-btn-label-color));
  }
  &:hover {
    color: var(--footer-btn-label-hover-color, var(--footer-btn-label-color));
  }

  @include respond-to(sm, up) {
    font-size: var(--footer-btn-label-font-size-m, var(--footer-btn-label-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--footer-btn-label-font-size-l, var(--footer-btn-label-font-size));
  }
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.button-icon {
  padding: var(--footer-btn-icon-bg-padding);
  background: var(--footer-btn-icon-bg-background);
  border-radius: var(--footer-btn-icon-bg-border-radius);

  svg {
    width: var(--footer-btn-icon-size);
    height: var(--footer-btn-icon-size);

    path {
      fill: var(--footer-btn-icon-color);
    }
  }
}

.header {
  order: var(--footer-header-flex-order);
  display: var(--footer-header-display);
  margin: var(--footer-header-margin);
  padding: var(--footer-header-padding);
}

.title {
  @include with-styled-text(footer-header);
}

.logo-container {
  order: var(--footer-logo-flex-order);
  align-items: var(--footer-logo-container-align-items);
  display: flex;
  flex-direction: var(--footer-logo-container-direction);
  justify-content: var(--footer-logo-container-justify-content);
  margin: var(--footer-logo-container-margin);
  padding: var(--footer-logo-container-padding);
}

.logo {
  height: var(--footer-logo-height);
  width: var(--footer-logo-width);
}

.text-container {
  margin: var(--app-custom-footer-text-container-margin);
  padding: var(--app-custom-footer-text-container-padding);
  background: var(--app-custom-footer-text-container-background);
}

.text {
  color: var(--app-custom-footer-text-colour);
  font-family: var(--app-custom-footer-text-font-face);
  font-size: var(--app-custom-footer-text-font-size);
  font-style: var(--app-custom-footer-text-font-style);
  font-weight: var(--app-custom-footer-text-font-weight);
  letter-spacing: var(--app-custom-footer-text-letter-spacing);
  line-height: var(--app-custom-footer-text-line-height);
  text-align: var(--app-custom-footer-text-align);

  > p {
    margin: var(--app-custom-footer-text-margin);
    padding: var(--app-custom-footer-text-padding);
  }
}

.footer {
  order: var(--footer-text-flex-order);

  // site footer container
  --app-custom-footer-text-container-margin: var(--footer-text-container-margin);
  --app-custom-footer-text-container-padding: var(--footer-text-container-padding);
  --app-custom-footer-text-container-background: var(--footer-text-container-background);

  @include respond-to(md, up) {
    --app-custom-footer-text-container-margin: var(--footer-text-container-margin-m, var(--footer-text-container-margin));
    --app-custom-footer-text-container-padding: var(--footer-text-container-padding-m, var(--footer-text-container-padding));
  }

  @include respond-to(lg, up) {
    --app-custom-footer-text-container-margin: var(--footer-text-container-margin-l, var(--footer-text-container-margin));
    --app-custom-footer-text-container-padding: var(--footer-text-container-padding-l, var(--footer-text-container-padding));
  }

  // site footer legal text
  --app-custom-footer-text-margin: var(--footer-text-margin);
  --app-custom-footer-text-padding: var(--footer-text-padding);

  --app-custom-footer-text-colour: var(--footer-text-colour);
  --app-custom-footer-text-font-face: var(--footer-text-font-face);
  --app-custom-footer-text-font-size: var(--footer-text-font-size);
  --app-custom-footer-text-font-weight: var(--footer-text-font-weight);
  --app-custom-footer-text-font-style: var(--footer-text-font-style);
  --app-custom-footer-text-line-height: var(--footer-text-line-height);
  --app-custom-footer-text-letter-spacing: var(--footer-text-letter-spacing);
  --app-custom-footer-text-text-transform: var(--footer-text-text-transform);
  --app-custom-footer-text-align: var(--footer-text-text-align);

  @include respond-to(md, up) {
    --app-custom-footer-text-font-size: var(--footer-text-font-size-m, var(--footer-text-font-size));
    --app-custom-footer-text-margin: var(--footer-text-margin-m, var(--footer-text-margin));
    --app-custom-footer-text-padding: var(--footer-text-padding-m, var(--footer-text-padding));
  }

  @include respond-to(lg, up) {
    --app-custom-footer-text-font-size: var(--footer-text-font-size-l, var(--footer-text-font-size));
    --app-custom-footer-text-margin: var(--footer-text-margin-l, var(--footer-text-margin));
    --app-custom-footer-text-padding: var(--footer-text-padding-l, var(--footer-text-padding));
  }
}
