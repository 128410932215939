@import '../../resources/vars';
@import '../../resources/mixins';

.wrapper {
  width: 100%;
  max-width: 100%;

  @include respond-to(sm, up) {
    max-width: var(--coach-btn-max-width-m);
  }

  @include respond-to(lg, up) {
    max-width: var(--coach-btn-max-width-l);
  }
}

.unchecked {
  --app-custom-input-controller-padding: var(--input-radio-outer-container-padding);
  --app-custom-input-controller-background: var(--input-radio-outer-container-background);
  --app-custom-input-controller-border: var(--input-radio-outer-container-border);
  --app-custom-input-controller-border-radius: var(--input-radio-outer-container-border-radius);
  --app-custom-input-label-color: var(--input-radio-label-colour);
}

.checked {
  --app-custom-input-controller-background: var(--input-radio-outer-container-background-on);
  --app-custom-input-controller-border: var(--input-radio-outer-container-border-on);
  --app-custom-input-label-color: var(--input-radio-label-colour-on);
}
