@import '../resources/vars';
@import '../resources/mixins';

.provider {
  --input-spacer: var(--input-group-spacer);
  --input-align: var(--input-group-align);
  --input-group-width: var(--input-group-max-width, 100%);
  --input-btn-width: var(--btn-max-width, 100%);

  &.coach {
    --input-spacer: var(--coach-spacer-input);

    /* All types - Main container */
    --app-custom-btn-align: var(--coach-btn-align);
    --app-custom-btn-max-width: var(--coach-btn-max-width);
    --app-custom-btn-width: var(--coach-btn-width);
    --app-custom-btn-height: var(--coach-btn-height);
    --app-custom-btn-margin: var(--coach-btn-margin);
    --app-custom-btn-padding: var(--coach-btn-padding);
    --app-custom-btn-border-radius: var(--coach-btn-border-radius);
    --app-custom-btn-border: var(--coach-btn-border);
    --app-custom-btn-effect-disabled: var(--coach-btn-effect-disabled);

    @include respond-to(sm, up) {
      --app-custom-btn-max-width-m: var(--coach-btn-max-width-m);
      --app-custom-btn-height-m: var(--coach-btn-height-m);
      --input-spacer: var(--coach-spacer-input-m, var(--coach-spacer-input));
    }

    @include respond-to(lg, up) {
      --app-custom-btn-max-width-l: var(--coach-btn-max-width-l);
      --app-custom-btn-height-l: var(--coach-btn-height-l);
      --input-spacer: var(--coach-spacer-input-l, var(--coach-spacer-input));
    }

    /* All types - Icon container */
    --app-custom-btn-icon-display: var(--coach-btn-icon-display);
    --app-custom-btn-icon-border: var(--coach-btn-icon-border);
    --app-custom-btn-icon-border-radius: var(--coach-btn-icon-border-radius);
    --app-custom-btn-icon-width: var(--coach-btn-icon-width);
    --app-custom-btn-icon-height: var(--coach-btn-icon-height);
    --app-custom-btn-icon-margin: var(--coach-btn-icon-margin);

    /* All types - SVG */
    --app-custom-btn-icon-size: var(--coach-btn-svg-size);

    /* All types - label */
    --app-custom-btn-label-padding: var(--coach-btn-label-padding);
    --app-custom-btn-label-text-transform: var(--coach-btn-label-text-transform);
    --app-custom-btn-label-face: var(--coach-btn-label-face);
    --app-custom-btn-label-font-weight: var(--coach-btn-label-font-weight);
    --app-custom-btn-label-font-size: var(--coach-btn-label-font-size);
    --app-custom-btn-label-letter-spacing: var(--coach-btn-label-letter-spacing);
    --app-custom-btn-label-max-width: var(--coach-btn-label-max-width);
    --app-custom-btn-label-text-align: var(--coach-btn-label-text-align);

    @include respond-to(sm, up) {
      --app-custom-btn-label-font-size-m: var(--coach-btn-label-font-size-m, var(--coach-btn-label-font-size));
    }

    @include respond-to(lg, up) {
      --app-custom-btn-label-font-size-l: var(--coach-btn-label-font-size-l, var(--coach-btn-label-font-size));
    }

    /* Button block overrides - Major option */
    --app-custom-btn-major-background: var(--coach-btn-major-background);
    --app-custom-btn-major-border: var(--coach-btn-major-border);
    --app-custom-btn-major-box-shadow: var(--coach-btn-major-box-shadow);
    --app-custom-btn-major-icon-bg-color: var(--coach-btn-major-icon-bg-color);
    --app-custom-btn-major-icon-border: var(--coach-btn-major-icon-border);
    --app-custom-btn-major-icon-color: var(--coach-btn-major-icon-color);
    --app-custom-btn-major-label-color: var(--coach-btn-major-label-color);
    --app-custom-btn-major-label-font-weight: var(--coach-btn-major-label-font-weight);

    /* Button block overrides - Minor option */
    --app-custom-btn-minor-background: var(--coach-btn-minor-background);
    --app-custom-btn-minor-border: var(--coach-btn-minor-border);
    --app-custom-btn-minor-box-shadow: var(--coach-btn-minor-box-shadow);
    --app-custom-btn-minor-icon-bg-color: var(--coach-btn-minor-icon-bg-color);
    --app-custom-btn-minor-icon-border: var(--coach-btn-minor-icon-border);
    --app-custom-btn-minor-icon-color: var(--coach-btn-minor-icon-color);
    --app-custom-btn-minor-label-color: var(--coach-btn-minor-label-color);
    --app-custom-btn-minor-label-font-weight: var(--coach-btn-minor-label-font-weight);
  }

  &.stack {
    --input-spacer: 0;
  }

  &.question {
    --input-height: var(--btn-companion-height);
  }
}
